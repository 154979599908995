<template>
  <div class="container">
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/guangDongLenjoy/QQMusic/top_01.png" />
    <div class="form">
      <img class="one" v-if="num == 1" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif" />
      <van-field v-model="form.phone" type="tel" maxlength="11" placeholder="请输入您的手机号码" @input="changePhone" />
      <img class="two" v-if="num == 2" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif" />
      <img class="three" v-if="num == 3" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif" />
      <template>
        <van-field v-model="form.code" type="number" center maxlength="6" autocomplete="one-time-code"
          placeholder="请输入验证码" :class="['input-code', { 'two-cell': num == 2 }]">
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>

      <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/submit-lijidinggou.gif" class="submit"
        @click="onSubmit" />
      <img class="four" v-if="num == 4" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_06.gif" />
      <div class="checked">
        <van-checkbox checked-color="#fb663e" v-model="checked" icon-size="16px">
          我已阅读并同意<span class="book" @click.stop="showDialog('《隐私条款》')">《隐私条款》</span><span class="book"
            @click.stop="showDialog('《合约业务受理协议》')">《合约业务受理协议》</span>
        </van-checkbox>
      </div>
    </div>
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/guangDongLenjoy/QQMusic/card1.gif" />
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/guangDongLenjoy/QQMusic/card2.png" />
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/guangDongLenjoy/QQMusic/info2.png" />
    <BaseDialogConfirm :width="698" :height="683"
      background="https://cdn.qiumo.net.cn/allsmarketmaterial/guangDongLenjoy/QQMusic/dialog3.png"
      :visibility.sync="visibility"
      closeBtnImg="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/zhejiang/specialOffer29/dialog_submit_close.png"
      submitBtnImg="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/zhejiang/specialOffer29/dialog_submit_querendinggou.png" />
  </div>
</template>

<script>
import { getVerificationCode, tradeOrder } from "@/api/qiuMoFusion/index";
import BaseDialogConfirm from "@/components/BaseDialog/confirm.vue";

export default {
  components: {
    BaseDialogConfirm,
  },
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        productNo: "514204115106",
        phone: "",
        characteristic: "",
        marketUrl: "",
        code: "", //绑定输入验证码框框
        sid: "",
        data: "",
        orderId: "",
      },
      num: 1,
      checked: false,
      visibility: false,
    };
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      phone,
      characteristic: from + reasonCode,
      marketUrl: encodeURIComponent(href),
    };
    // let state = {
    //   url: document.URL,
    // };
    // history.pushState(state, "title", document.URL);
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && code.length == 0) {
          this.num = 2;
        } else if (code.length < 6) {
          this.num = 3;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changePhone(value) {
      // if (value.length == 11) {
      //   this.checked = true;
      // }
    },
    getAuthCode() { },
    handleSubmit() {
      this.visibility = false;
      return;
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.$toast.clear();
        if (res.code !== 0) {
          this.$toast({
            duration: 8000,
            message: res.message || "提交失败",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                this.form.phone,
                "失败"
              )
            );
          }, 3000);
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "center",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                this.form.phone,
                "成功"
              )
            );
          }, 3000);
        }
      });
    },
    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else if (!this.checked) {
        this.$toast({
          message: "请阅读并同意《隐私条款》《合约业务受理协议》",
        });
      } else {
        this.visibility = true;
      }
    },
    showDialog(title) {
      const data = {
        "《隐私条款》": `
《关于客户个人信息收集、使用及保护的公告》

尊敬的客户：

您好！ 

根据《中华人民共和国网络安全法》《全国人民代表大会常务委员会关于加强网络信息保护的决定》《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。您在使用套餐类业务办理、通用流量包类业务办理、定向流量包类业务办理、权益+流量包办理、语音短信等这类业务办理服务时，我公司可能需要收集和使用您的手机号码、订购短信验证码，确保成功为您办理业务。 

经过您的授权同意后，在符合相关法律法规与隐私政策的情况下，上述信息会提供给我公司的合作运营商中国联合网络通信有限公司（以下简称“中国联通”），用于为您办理业务。中国联通隐私政策的详细内容请点击此处查询 <a href='https://docs.qq.com/doc/DTVJGUlVZTU10ZUFV' >https://docs.qq.com/doc/DTVJGUlVZTU10ZUFV。</a>

为向客户提供优质、个性化的服务，我公司将遵循合法、正当、必要与诚信的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息： 
 
（1）事先获得客户的明确授权；

（2）根据有关的法律法规要求； 

（3）按照相关司法机关和/或政府主管部门的要求；

（4）为维护社会公众的利益所必需且适当；  

（5）为维护我公司或客户的合法权益所必需且适当； 

（6）为确保您能成功办理中国联通独立或与我们合作的运营活动，我们会经过您的单独同意后，将您提交的信息提供给中国联通。 
      `,
        "《合约业务受理协议》": `
如您勾选并继续订购本产品，则视为您知悉并同意本订购须知的内容。

产品名称：24.9元乐享月卡会员（QQ音乐版）

资费：24.9元/月

产品内容：产品权益（每月包含）：

（一）QQ音乐会员；

（二）5GB国内通用流量（享5G基础速率服务）

业务规则

（一）办理规则

（二）计费规则：从话费一次性扣费，赠款不可抵扣。

（三）生效规则：订购后立即生效，并从话费中扣取产品资费。

（四）退订规则：

（五）其他规则
`,
      };
      this.$dialog.alert({
        messageAlign: "left",
        title: title,
        message: data[title],
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.container {
  background-color: #fb3136;

  .img {
    width: 100%;
  }

  .form {
    position: relative;

    .one,
    .two,
    .three,
    .four {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    .one {
      transform: translate(80px, -80px);
    }

    .two {
      width: 166px;
      right: 40px;
      top: 50px;
    }

    .three {
      width: 456px;
      transform: translate(80px, -20px);
    }

    .four {
      width: 270px;
      transform: translate(200px, -94px);
    }

    ::v-deep .van-cell {
      width: 680px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 50px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 46px;
      border: solid 3px #b2b2b2;

      &.input-code {
        margin-top: 45px;

        .code {
          width: 229px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          color: #ffffff;
          font-size: 30px;
          font-weight: normal;
          border-left: solid 3px #b2b2b2;
          background-color: #ff840d;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;

        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 400;
          color: #969696;
        }
      }
    }

    .submit {
      width: 660px;
      display: block;
      margin: 50px auto 0;
      animation: identifier 2s infinite;

      @keyframes identifier {
        0% {
          transform: scale(1);
        }

        25% {
          transform: scale(0.9);
        }

        50% {
          transform: scale(1);
        }

        75% {
          transform: scale(0.9);
        }
      }
    }

    .checked {
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      padding-bottom: 80px;

      ::v-deep .van-checkbox {
        width: auto;

        &__label {
          color: #fff;
        }
      }

      .book {
        font-weight: 400;
        // color: #f8f107;
      }
    }
  }

  ::v-deep .dialog-confirm {
    .footer {
      padding-top: 570px;
      align-items: center;

      .btn-close {
        width: 180px;
      }

      .btn-submit {
        height: 72px;
        width: 215px;
      }
    }
  }
}
</style>